<template>
评论
</template>

<script>
export default {
name: "Comment"
}
</script>

<style scoped>

</style>