<template>
    <div class="main" v-loading="loading">
        <h3 v-show="!loading">博客列表</h3>
        <el-table :data="articles" empty-text="没有博客" stripe v-show="!loading">
            <el-table-column prop="id" label="ID"  width="50"/>
            <el-table-column label="标题" width="400">
                <template #default="scope">
                    <el-link :href="getArticleUrl(scope.row.id)" target="_blank">{{scope.row.title}}</el-link>
                </template>
            </el-table-column>
            <el-table-column prop="description" label="描述" />
            <el-table-column label="发布时间" width="180"> 
                <template #default="scope">
                    {{ formatDate(scope.row.createTime) }}
                </template>
            </el-table-column>
            <el-table-column prop="readTimes" label="浏览次数" fixed="right" width="80"/>
            <el-table-column label="操作" fixed="right" width="100">
              <template #default="scope">
                <div style="display: flex; justify-content: space-between">
                    <el-button type="text" class="edit-btn">编辑</el-button>
                    <el-button type="text" class="edit-btn">删除</el-button>
                </div>
              </template>
            </el-table-column>
        </el-table>
        
    </div>
    <div v-show="!loading" class="footer">
            <el-pagination v-show="isShow" @current-change="getArticleList" background layout="prev, pager, next" :page-count="pages" >
            </el-pagination>
    </div>
</template>

<script>
import moment from "moment";

moment.locale("zh-cn");
export default {
    name: "Blog",
    data() {
        return {
            pages: -1,
            articles: [],
            isShow: true,
            loading: false,
        }
    },
    methods: {
        getArticleList(current) {
            window.scrollTo(0,0);
            this.loading = true
            this.axios.get('/article/list/', {
                params: {
                next: current,
                size: 10
                }
            }).then(res => {
                if (res.data.code === 0){
                    this.loading = false
                    this.articles = res.data.data.articleCards;
                    this.pages = res.data.data.pages;
                }else {
                    ElMessage.warning({
                        showClose: true,
                        message: res.data.data
                    })
                }
            }).catch(error => {
                console.log(error);
                ElMessage.error({
                showClose: true,
                message: "服务器异常"
                })
                this.loading = false
            })
        },
        formatDate(date) {
            if (date === null) {
                return "";
            } else {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
            }
        },
        getArticleUrl(id) {
            return "https://blog.cserwen.com/article/" + id;
        },
    },
    mounted() {
        this.getArticleList(1)
    },
}
</script>

<style scoped>
.main {
    padding: 0 50px;
    min-height: 75vh;
}

.edit-btn {
    border: none;
    text-decoration: none;
    color: rgb(7, 156, 248);
    background: none;
}

.edit-btn:hover,
.edit-btn:focus {
  color: rgb(66, 233, 245);
}

.footer {
    margin-top: 20px;
    text-align: center;
}

</style>